<template>
  <div class="recg-aibox">
    <div>
      <AiboxTable :data="aiBoxes" />
    </div>
    <component class="active-comp" :is="activeComp" />
  </div>  
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'RecgAibox',
  components: {
    AiboxTable: () => import('@/components/AiBox/AiboxTable.vue'),
    RecognitionLpr: () => import('@/components/AiBox/RecognitionLpr.vue'),
    RecognitionOr: () => import('@/components/AiBox/RecognitionOr.vue'),
  },
  data() {
    return {
      msg: 'RecgAibox'
    }
  },
  computed: {
    ...mapState('aibox', ['aiMode', 'aiBoxes']),
    ...mapGetters('aibox', ['aiBoxTask']),
    activeComp() {
      if (this.aiMode === 'lpr') return 'RecognitionLpr'
      if (this.aiMode === 'or') return 'RecognitionOr'
      // if (this.aiMode === 'fr') return 'RecognitionFr'
      return null
    }
  },
  created() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    
  }
}
</script>

<style lang="scss" scoped>
.recg-aibox {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 24px 16px 16px;
  .active-comp {
    height: calc(100% - 170px);
  }
}
</style>